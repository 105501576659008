/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 40번째 로그에서는 Tech야,놀자 행사 준비, 기술 블로그 운영, 야놀자 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qr-kakaopay.kakao.com/smallshop"
  }, "카카오페이 QR결제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/totuworld"
  }, "totu 🥩🔥 (@totuworld) / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yanolja.com/"
  }, "야놀자 | 국내도 해외도 초특가 야놀자")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://yanolja.github.io/2019/07/tech_yanolja"
  }, "Tech야,놀자 - 1 | 야놀자 기술블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://yanolja.github.io/"
  }, "야놀자 기술블로그 - 누구나 마음 편히 놀 수 있게")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/354"
  }, "Seocho.rb 세 번째 모임 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://map.naver.com/local/siteview.nhn?code=11889227"
  }, "야놀자 본사: 네이버 지도")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://help.naver.com/support/contents/contents.help?serviceNo=606&categoryNo=10246"
  }, "네이버 고객센터 - 인물정보를 등록하고 싶어요")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://search.naver.com/search.naver?sm=top_hty&fbm=1&ie=utf8&query=stdout.fm"
  }, "stdout.fm : 네이버 통합검색")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://search.naver.com/search.naver?where=nexearch&sm=tab_jum&query=%EB%B3%B4%EB%9E%8C%ED%8A%9C%EB%B8%8C"
  }, "보람튜브 : 네이버 통합검색")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://engineering.linecorp.com/ko/blog/"
  }, "Blog - LINE ENGINEERING")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn"
  }, "당근마켓 팀블로그 – Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/myrealtrip-product"
  }, "How we build MyRealTrip – Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://totuworld.gitbooks.io/unity_ngui/content/"
  }, "Introduction | 따라하면서 배우는 NGUI 유니티 2D 게임 프로그래밍")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.totu.dev/2019/07/25/tech_yanolja_postmortem/"
  }, "Tech야,놀자 준비 후기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://milimtower.co.kr/"
  }, "미림타워")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://it.chosun.com/site/data/html_dir/2019/06/11/2019061102764.html"
  }, "야놀자 국내 '8번째 유니콘' 됐다…2000억원 투자 유치 - IT조선 > 기업 > 중기·벤처")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn/aws-lambda%EB%A5%BC-%EC%9D%B4%EC%9A%A9%ED%95%9C-%EC%9D%B4%EB%AF%B8%EC%A7%80-%EC%8D%B8%EB%84%A4%EC%9D%BC-%EC%83%9D%EC%84%B1-%EA%B0%9C%EB%B0%9C-%ED%9B%84%EA%B8%B0-acc278d49980"
  }, "AWS Lambda를 이용한 이미지 썸네일 생성 개발 후기 - 당근마켓 팀블로그 - Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn/lambda-edge%EB%A1%9C-%EA%B5%AC%ED%98%84%ED%95%98%EB%8A%94-on-the-fly-%EC%9D%B4%EB%AF%B8%EC%A7%80-%EB%A6%AC%EC%82%AC%EC%9D%B4%EC%A7%95-f4e5052d49f3"
  }, "AWS Lambda@Edge에서 실시간 이미지 리사이즈 & WebP 형식으로 변환 - 당근마켓 팀블로그 - Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.goodchoice.kr/"
  }, "대한민국 1등 종합숙박앱 - 여기어때")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://yanolja.github.io/2018/09/Work-Log"
  }, "워크로그 개발기 | 야놀자 기술블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://yanolja.github.io/2017/08/Hello-world"
  }, "야놀자 기술 블로그 만들기 | 야놀자 기술블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wikibook.co.kr/unity-ngui/"
  }, "따라 하면서 배우는 NGUI 유니티 2D | 위키북스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.totu.dev/"
  }, "totuworld's tech blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pragprog.com/"
  }, "The Pragmatic Bookshelf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/user/totuworld7/feed"
  }, "Yochang Song - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UCbWVkEp_42rg5s2GMP4d_IQ"
  }, "시피 Seapy - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.prisma.io/"
  }, "Prisma")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://subicura.com/"
  }, "Subicura's Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://code.visualstudio.com/"
  }, "Visual Studio Code - Code Editing. Redefined")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/webstorm/"
  }, "WebStorm: The Smartest JavaScript IDE by JetBrains")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
